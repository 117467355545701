/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'battery-full': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 6h10v4H2z"/><path pid="1" d="M2 4a2 2 0 00-2 2v4a2 2 0 002 2h10a2 2 0 002-2V6a2 2 0 00-2-2zm10 1a1 1 0 011 1v4a1 1 0 01-1 1H2a1 1 0 01-1-1V6a1 1 0 011-1zm4 3a1.5 1.5 0 01-1.5 1.5v-3A1.5 1.5 0 0116 8"/>',
    },
});
